.button-upload {
    padding: 50px;
    border-style: dashed;
    border-color: #f6931c;
    color: #f6931c;
    background: #fcdeba;
    cursor: pointer;
    transition: border-style 0.2s;
}

.button-upload:hover {
    border-style: solid;
    border-color: #f6931c;
}

.clean-preview {
    border: 0;
    background: transparent;
}